// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myndir-js": () => import("./../src/pages/myndir.js" /* webpackChunkName: "component---src-pages-myndir-js" */),
  "component---src-pages-panta-teikningu-js": () => import("./../src/pages/panta-teikningu.js" /* webpackChunkName: "component---src-pages-panta-teikningu-js" */),
  "component---src-pages-takk-js": () => import("./../src/pages/takk.js" /* webpackChunkName: "component---src-pages-takk-js" */),
  "component---src-pages-teikningar-js": () => import("./../src/pages/teikningar.js" /* webpackChunkName: "component---src-pages-teikningar-js" */),
  "component---src-pages-um-js": () => import("./../src/pages/um.js" /* webpackChunkName: "component---src-pages-um-js" */)
}

